import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { ZipFileJobRequestDto, ZipFileJobResponseDto } from 'src/backend';
import { RootState } from 'src/store';

const zipFileJobsAdapter = createEntityAdapter<ZipFileJobResponseDto>({
    selectId: (job) => job.id,
    sortComparer: (a, b) => String(a.id).localeCompare(b.id),
});


export const fetchZipFileJobs = createAsyncThunk(
    'package/zipFileJobs',
    async (loanId: string) => {
        const data = await api.getElementsZip(loanId)
        return data.jobs;
    }
)
// add job async thunk
export const createZipFileJob = createAsyncThunk(
    'package/zipFileJobs/add',
    async (args: { loanId: string, data: ZipFileJobRequestDto }) => {
        const data = await api.generateElementsZip(args.loanId, args.data)
        return [data];
    }
)

// remove job async thunk
export const removeZipFileJob = createAsyncThunk(
    'package/zipFileJobs/remove',
    async (args: { loanId: string, jobId: string }) => {
        const data = await api.deleteElementsZip(args.loanId, { jobid: args.jobId })
        return data;
    }
)

export const removeZipFileJobAndClear = createAsyncThunk(
    'package/zipFileJobs/removeAndClear',
    async (args: { loanId: string, jobId: string }) => {
        const data = await api.deleteElementsZip(args.loanId, { jobid: args.jobId })
        return data;
    }
)

export const clearZipFileJob = createAsyncThunk(
    'package/zipFileJobs/clear',
    async (jobId: string) => {
        return jobId;
    }
)

export const zipFileJobSlice = createSlice({
    name: 'zipFileJobs',
    initialState: zipFileJobsAdapter.getInitialState({
        loading: false,
        ids: [],
        entities: {},
        elements: [],
    }),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchZipFileJobs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createZipFileJob.fulfilled, (state, action) => {
            zipFileJobsAdapter.upsertMany(state, action.payload);
            state.loading = false;
        });
        builder.addCase(fetchZipFileJobs.fulfilled, (state, action) => {
            if (!!action.payload) {
                zipFileJobsAdapter.upsertMany(state, action.payload);
            }
            state.loading = false;
        });
        builder.addCase(removeZipFileJobAndClear.fulfilled, (state, action) => {
            zipFileJobsAdapter.removeOne(state, action.payload.id);
            state.loading = false;
        });
        builder.addCase(clearZipFileJob.fulfilled, (state, action) => {
            zipFileJobsAdapter.removeOne(state, action.payload);
            state.loading = false;
        });
    }
});

export const { reducer } = zipFileJobSlice;

export const {
    selectIds: selectZipFileJobIds,
    selectAll: selectAllZipFileJobs,
    selectTotal: selectTotalZipFileJobs,
} = zipFileJobsAdapter.getSelectors((state: RootState) => state[zipFileJobSlice.name]);
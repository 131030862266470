import * as React from "react"

export const PageErrorGraph = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={94}
        height={93}
        fill="none"
        viewBox="0 0 94 93"
        {...props}
    >
        <path
            fill="#7EB3FF"
            d="m57.845 14.02 14.523 14.505-12.337 3.308s-4.541-7.81-4.45-8.082c.092-.272 2.264-9.732 2.264-9.732Z"
        />
        <path
            fill="#EAF4FE"
            d="M70.25 27.61h-8.719a2.905 2.905 0 0 1-2.906-2.907v-8.719c0-1.605-1.3-2.906-2.906-2.906H23.75a2.905 2.905 0 0 0-2.906 2.906v61.032c0 1.605 1.3 2.906 2.906 2.906h46.5c1.606 0 2.906-1.3 2.906-2.906v-46.5c0-1.606-1.3-2.907-2.906-2.907Z"
        />
        <path
            fill="#FC5555"
            d="M63.34 59.466 51.754 79.535c-1.119 1.937.28 4.36 2.517 4.36h23.175c2.237 0 3.635-2.423 2.516-4.36l-11.587-20.07c-1.119-1.938-3.916-1.938-5.033 0Z"
        />
        <path
            fill="#fff"
            d="M65.89 79.922a1.453 1.453 0 1 0 0-2.906 1.453 1.453 0 0 0 0 2.906ZM65.246 74.108l-.8-7.266a1.453 1.453 0 1 1 2.887 0l-.799 7.266a.647.647 0 0 1-1.288 0Z"
        />
    </svg>
)

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

interface TemplateFilters {
    companyId: string;
}

interface AdminState {
    filters: TemplateFilters
}

const initialState: AdminState = {
    filters: {
        companyId: undefined,
    },
}

export const adminUiSlice = createSlice({
    name: "adminUi",
    initialState,
    reducers: {
        setCompanyId: (state, action: PayloadAction<string>) => {
            state.filters.companyId = action.payload;
        },
    }
})

export const adminUiTemplateFiltersSelector = (state: RootState) => state.adminUi.filters;


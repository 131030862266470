import type { LoanEntityLabel, LoanPhaseCategoryType, Role } from "src/backend";
import { EntityLabel } from "src/types/entity";
import type { LoanRole, LoanTableColumn } from "src/types/loan";

export const CardStatus = {
    PROCESSING: 'PROCESSING',
    PROCESSED: 'PROCESSED',
    ACCEPTED: 'ACCEPTED',
    NOT_ACCEPTED: "NOT_ACCEPTED",
    PROCESSED_INCOMPLETE: 'PROCESSED_INCOMPLETE',
    PROCESSED_ERROR: 'PROCESSED_ERROR',
} as const;

export const LoanStatus = {
    APPLYING: "APPLYING",
    ANALYZING: "ANALYZING",
    CONDITIONING: "CONDITIONING",
    CLOSING: "CLOSING",
    ARCHIVED: "ARCHIVED",
    SERVICING: "SERVICING",
} as const;

export const CardType = {
    LOAN_SUMMARY: 'LOAN_SUMMARY',
    LOAN_SUMMARIES: 'LOAN_SUMMARIES',
    ONBOARDING: 'ONBOARDING',
    CONGRATULATIONS: 'CONGRATULATIONS',
    PROCESSED: 'PROCESSED',
    MESSAGES: 'MESSAGES',
    DRIVERS_LICENSE: 'DRIVERS_LICENSE',
    DEMOGRAPHICS: 'DEMOGRAPHICS',
    SOFT_CREDIT_PULL: 'SOFT_CREDIT_PULL',
    IRS_4506C: 'IRS_4506C',
} as const;

export const LoanTemplateType = {
    Full: 'FULL',
    Fragment: 'BLOCK',
} as const;

export const LoanFragmentType = {
    Asset: 'ASSET',
    Entity: 'ENTITY',
} as const;

export const RE_LOAN_TYPES = [
    "CRE_BRIDGE",
    "CRE_PURCHASE",
    "CRE_A_AND_D",
    "CRE_CONSTRUCTION",
    "FIX_AND_FLIP",
    "SBA504_CRE_PURCHASE",
    "SBA504_CRE_CONSTRUCTION",
    "SIERRA_WEST",
    "SBA504_CRE_CONSTRUCTION",
    "RESIDENTIAL_CONSTRUCTION",
    "RESIDENTIAL_BRIDGE",
    "CRE_REHAB"
] as const;

export const EntityType = {
    Asset: 'ASSET',
    RealEstateAsset: 'RE_ASSET',
    Company: 'COMPANY',
    Trust: 'TRUST',
    Individual: 'INDIVIDUAL',
} as const;

export const UNSAVED_LOAN_ID = "unsavedLoan";

export const QUERY_PARAM_SELECT_BORROWER_PORTAL_MESSAGES_UNREAD_LOANS_DIALOG = 'selectUnreadMessagesLoanDialog';

export const QUERY_PARAM_SELECT_BORROWER_PORTAL_MESSAGES_NEW_LOANS_DIALOG = 'selectNewMessagesLoanDialog';

export const QUERY_PARAM_NEEDS_LIST_LOAN_ID = 'needsListLoanId';

export const QUERY_PARAM_SOURCES_AND_USES = 'sourcesAndUses';

export const QUERY_PARAM_COPY_FROM_LOAN_ID = 'copyFromLoanId';

export const QUERY_PARAM_PHASE_CATEGORY = 'phaseCategory';

export const BLANK_LOAN_TEMPLATE_ID = 'blankLoanTemplateId';
export const RoleType: { [key: string]: Role } = {
    Borrower: 'BORROWER',
    LeadBorrower: 'LEAD_BORROWER',
    Lender: 'LENDER',
    LeadLender: 'LEAD_LENDER',
    BORROWER: 'BORROWER',
    LEAD_BORROWER: 'LEAD_BORROWER',
    LENDER: 'LENDER',
    LEAD_LENDER: 'LEAD_LENDER',
    AssociateLender: 'ASSOCIATE_LENDER',
    Manager: 'MANAGER_LENDER',
    Underwriter: 'UNDERWRITER_LENDER',
    ContactBorrower: 'CONTACT_BORROWER',
    ContactLender: 'CONTACT_LENDER',
    KeyContact: 'KEY_CONTACT',
} as const;

export const SubRoleType = {
    "Default": "DEFAULT",
    "Vendor": "VENDOR",
    "Franchisor": "FRANCHISOR",
    "LandLord": "LANDLORD",
    "RalEstateAgent": "REAL_ESTATE_AGENT",
    "TitleAgent": "TITLE_AGENT",
    "InsuranceAgent": "INSURANCE_AGENT",
    "EnvironmentalAgent": "ENVIRONMENTAL_AGENT",
    "Appraiser": "APPRAISER",
    "Lawyer": "LAWYER",
    "PropertyInspector": "PROPERTY_INSPECTOR",
    "PropertyManager": "PROPERTY_MANAGER",
    "Contractor": "CONTRACTOR",
    "Tenant": "TENAT",
    "SpecialUser": "SPECIAL_USER"

}

const roleLabel = (roleType) => ({
    [RoleType.Borrower]: 'Borrower',
    [RoleType.LeadBorrower]: 'Lead',
    [RoleType.Lender]: 'Lender',
    [RoleType.AssociateLender]: 'Associate',
    [RoleType.LeadLender]: 'Lead',
    [RoleType.Manager]: 'Manager',
    [RoleType.Underwriter]: 'Underwriter',
    [RoleType.ContactBorrower]: 'Contact',
    [RoleType.ContactLender]: 'Contact',
    [RoleType.KeyContact]: 'Key Contact',
    ['AFFILIATE']: 'Affiliate',
    ['RELATED_NON_AFFILIATE']: 'Related Non-Affiliate',
    ['NON_AFFILIATE']: EntityLabel['NON_AFFILIATE'].long,
    ['EPC']: EntityLabel['EPC'].long,
    ['OPERATING_COMPANY']: EntityLabel['OPERATING_COMPANY'].long,
    ['PRIMARY_OPERATION_COMPANY']: EntityLabel['PRIMARY_OPERATION_COMPANY'].long,
    ['SECURED_LIMITED']: EntityLabel['SECURED_LIMITED'].long,
    ['SECURED_FULL']: EntityLabel['SECURED_FULL'].long,
    ['UNSECURED_FULL']: EntityLabel['UNSECURED_FULL'].long,
    ['UNSECURED_LIMITED']: EntityLabel['UNSECURED_LIMITED'].long,
    ['GUARANTOR']: 'Guarantor',
    ['SPOUSE']: 'Spouse',
    ['FRANCHISEE']: 'Franchise',
    ['MEMBER']: 'Member',
    ['APPLICANT']: 'Borrower',
}[roleType])

const RoleList: Record<Role, Role> = {
    BORROWER: "BORROWER",
    LEAD_BORROWER: "LEAD_BORROWER",
    LENDER: "LENDER",
    LEAD_LENDER: "LEAD_LENDER",
    MANAGER_LENDER: "MANAGER_LENDER",
    UNDERWRITER_LENDER: "UNDERWRITER_LENDER",
    ASSOCIATE_LENDER: "ASSOCIATE_LENDER",
    CONTACT_BORROWER: "CONTACT_BORROWER",
    CONTACT_LENDER: "CONTACT_LENDER",
    KEY_CONTACT: "KEY_CONTACT",
    NONE: "NONE",
}

export const RoleTypeLabel = (roleType: Role | LoanEntityLabel, loanRoles?: LoanRole[]): string => {
    const isRoleTypeARole = Object.values(RoleList).includes(roleType as Role);
    const multiRole = isRoleTypeARole &&
        !!loanRoles &&
        isLeaderRole(roleType as Role) &&
        loanRoles.filter(loanRole => loanRole.role === roleType).length > 1;

    if (multiRole) return 'Co-Lead';

    // if roleType is not defined, return empty string
    if (!roleType) return '';

    // if we don't have a label, return the roleType
    return roleLabel(roleType) || roleType;
};

const isLeaderRole = (roleType: Role) => {
    return roleType === RoleType.LeadBorrower || roleType === RoleType.LeadLender;
}

const LoanPhaseCategoryTypeList: Record<LoanPhaseCategoryType, LoanPhaseCategoryType> = {
    LEAD: "LEAD",
    ORIGINATION: "ORIGINATION",
    ARCHIVE: "ARCHIVE",
    PORTFOLIO: "PORTFOLIO"
}
export const LoanStageCategory = LoanPhaseCategoryTypeList;

export const LoansKanbanFilter = {
    LENDER_LOANS: 'LENDER_LOANS',
    COMPANY_LOANS: 'COMPANY_LOANS',
    PINNED: 'PINNED',
    UNPINNED: 'UNPINNED',
    TEAMMATES: 'TEAMMATES'
} as const;

export enum DownPaymentSource {
    Unset = 'Select',
    CASH = 'Cash',
    HELOC = 'HELOC',
    LAND_EQUITY = 'Land Equity',
    SECOND_MORTGAGE = '2nd Mortgage',
    INVESTOR = 'Investor',
    OTHER = 'Other',
};

export const LoanListViewColumns: LoanTableColumn[] = [
    { id: 'loanID', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'Loan ID' },
    { id: 'projectName', isComponent: false, value: '', sortable: true, numeric: false, align: 'left', formattedValue: '', label: 'Project Name' },
    { id: 'purchasePrice', isComponent: false, value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Purchase Price' },
    { id: 'projectCosts', isComponent: false, value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Project Costs' },
    { id: 'totalCosts', isComponent: false, value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Total Costs' },
    // { id: 'loanAmount', isComponent:false,value: '', sortable: true, numeric: true, formattedValue: '', label: 'Requested Loan Amount' },
    { id: 'pmla', isComponent: false, value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Loan Amount' },
    { id: 'ltv', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'LTV' },
    { id: 'ltc', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'LTC' },
    { id: 'apr', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'APR' },
    // { id: 'loanTermInYears', isComponent:false,value: '', sortable: true, numeric: false, formattedValue: '', label: 'Amortization (Y)' },
    { id: 'closeDate', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'Close Date' },
    { id: 'dateCreated', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'Date Created' },
    { id: 'loanType', isComponent: false, value: '', sortable: true, numeric: false, formattedValue: '', label: 'Loan Type' },
    { id: 'actualLoanTerm', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'Term' },
    { id: 'loanStatus', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'Loan Status' },
    { id: 'applicants', isComponent: true, value: '', sortable: false, numeric: false, align: 'center', formattedValue: '', label: 'Borrowing Team' },
    { id: 'lenders', isComponent: true, value: '', sortable: false, numeric: false, align: 'center', formattedValue: '', label: 'Lending Team' },
    { id: 'downPayment', isComponent: false, value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Down Payment' },
    { id: 'actualLoanAmount', isComponent: false, value: '', sortable: true, numeric: true, align: 'right', formattedValue: '', label: 'Req. Loan Amount' },
    { id: 'amortization', isComponent: false, value: '', sortable: true, numeric: false, align: 'center', formattedValue: '', label: 'Amortization' },
    { id: 'loanProgress', isComponent: true, value: '', sortable: false, numeric: false, formattedValue: '', label: 'Loan Progress' },
]


const borrowerColumns = [
    'projectName',
    'downPayment',
    'purchasePrice',
    'projectCosts',
    'totalCosts',
    'dateCreated',
    'phaseCategory',
    // hidden
    'loanID',
    'loanType',
    'lenders',
    'applicants',
    'loanProgress',

]

export const BorrowerListViewColumns: LoanTableColumn[] = [...LoanListViewColumns
    .filter(column => borrowerColumns.includes(column.id)),
{ id: 'phaseCategory', isComponent: true, value: '', align: 'center', sortable: true, numeric: false, formattedValue: '', label: 'Loan Stage' },
];

export const LoanListViewFixedColumns = [
    {
        id: 'projectName',
    },
    {
        id: 'loanID',
    },
    {
        id: 'closeDate',
    },
];

export const LoanListViewColumnsDefaultOrder = LoanListViewColumns.map(column => column.id);

export const BorrowerListViewColumnsDefaultOrder = BorrowerListViewColumns
    .map(column => column.id)
    .sort((a, b) => {
        // sort by order in borrowerColumns
        return borrowerColumns.indexOf(a) - borrowerColumns.indexOf(b);
    });

export const MAX_LOAN_TITLE_LENGTH = 32;


export const DEFAULT_LOANS_PER_PAGE = 5;


export const LoanSearchAttributes = ['projectName', 'shortCode', 'templateProjectName', 'reviewStatus'];

export const QUERY_PARAM_EXPORT_TO_VENTURES_DIALOG_OPEN = 'exportToVenturesDialogOpen';

export const LoanViewTypeLabelMapping = {
    ["CONVENTIONAL"]: "Conventional",
    ["CONSOLIDATED_LENDER"]: "Simple",
    ["SBA504"]: "SBA 504",
    ["SBA7A"]: "SBA 7(a)",
}
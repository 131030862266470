import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { LoanTemplateFormValues } from 'src/components/loans/templates/template-form/template-form';
import { type AppThunk, RootState } from 'src/store';
import { LoanTemplate } from 'src/types/loan';

interface LoanTemplateState {
    templates: Record<string, LoanTemplate>;
}

const initialState: LoanTemplateState = {
    templates: {}
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setLoanTemplates(
            state: LoanTemplateState,
            action: PayloadAction<LoanTemplate[]>
        ): void {
            action.payload.forEach((template) => {
                if (!isEqual(state.templates[template.id], template)) {
                    state.templates[template.id] = template;
                }
            });
        }
    }
});


export const getLoanTemplates = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const templates = await api.getLoanTemplates();
        dispatch(slice.actions.setLoanTemplates(templates));
    } catch (e) { }
};

export const getLoanTemplate = (id: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const template = await api.getLoanTemplate(id);
        dispatch(slice.actions.setLoanTemplates([template]));
    } catch (e) {

    }
};

export const postCreateLoanTemplate = (values: LoanTemplateFormValues): AppThunk => async (dispatch): Promise<void> => {
    const template = await api.postLoanTemplate(values);
    dispatch(slice.actions.setLoanTemplates([template]));
};

export const putLoanTemplate = (values: Partial<LoanTemplateFormValues>): AppThunk => async (dispatch): Promise<void> => {
    const template = await api.putLoanTemplate(values);
    dispatch(slice.actions.setLoanTemplates([template]));
};

export const loanTemplatesSelector = createSelector<[(state: RootState) => any], LoanTemplate[]>((state: RootState) => state.loanTemplate.templates, (templates) => templates);

export const loanTemplateSelector = (id: string) => createSelector(
    (state: RootState) => state.loanTemplate.templates,
    (templates) => {
        return templates[id]
    }
);

export const { reducer, actions } = slice;
import { AppUserChangePasswordRequestDto, CompanyListDto, SecurityResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

export const adminApi = baseApi.enhanceEndpoints({ addTagTypes: ['CompanyListDto'] }).injectEndpoints({
    endpoints: (build) => ({
        adminResetPassword: build.mutation<SecurityResponseDto, AppUserChangePasswordRequestDto>({
            query: (data) => ({
                url: `/v1/auth/adminResetPassword`,
                method: 'POST',
                data,
            })
        }),
        getCompanies: build.query<CompanyListDto[], void>({
            query: () => ({
                url: '/v1/lenders/companies',
                method: 'GET',
            }),
            transformResponse: (response: CompanyListDto[]) => response.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetCompaniesQuery,
    useAdminResetPasswordMutation,
} = adminApi;




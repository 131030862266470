import { styled } from "@mui/material/styles"

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
}));

const BackButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(3),
    justifyContent: 'flex-start',
    width: '100%',
}));

const Message = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
    textAlign: 'center',
    gap: theme.spacing(2),
    '& svg': {
        marginBottom: theme.spacing(5),
    }
}));

export const AppErrorBoundaryStyles = {
    Container,
    BackButtonWrapper,
    Message
}
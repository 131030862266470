import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { AppUserDTO2, CompanyDto, Features, ViewType } from 'src/backend';
import { AppThunkPromise, RootState } from 'src/store';

interface ProfileState {
    loggedInUser: AppUserDTO2;
    needOnboarding: boolean;
    lenders: CompanyDto[];
    viewType: ViewType;
    features: Features[];
    company: CompanyDto;
}

const initialState: ProfileState = {
    loggedInUser: null,
    needOnboarding: false,
    lenders: [],
    viewType: null,
    features: [],
    company: null
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setLoggedInUser: (state: ProfileState, action: PayloadAction<AppUserDTO2>) => {
            state.loggedInUser = action.payload;
        },
        setNeedOnboarding: (state: ProfileState, action: PayloadAction<boolean>) => {
            state.needOnboarding = action.payload;
        },
        setLenders: (state: ProfileState, action: PayloadAction<CompanyDto[]>) => {
            state.lenders = action.payload;
        },
        setViewType: (state: ProfileState, action: PayloadAction<ViewType>) => {
            state.viewType = action.payload;
        },
        setFeatures: (state: ProfileState, action: PayloadAction<Features[]>) => {
            state.features = action.payload;
        },
        setCompany: (state: ProfileState, action: PayloadAction<CompanyDto>) => {
            state.company = action.payload;
        }
    }
});

export const getLoggedInUser = (): AppThunkPromise<AppUserDTO2> => async (dispatch): Promise<AppUserDTO2> => {
    const result = await api.getLoggedUser();
    if (!result.user) {
        return null;
    }
    dispatch(profileSlice.actions.setLoggedInUser(result.user));
    dispatch(profileSlice.actions.setNeedOnboarding(result.needOnboard));
    dispatch(profileSlice.actions.setLenders(result.lenders));
    dispatch(profileSlice.actions.setViewType(result.viewType));
    dispatch(profileSlice.actions.setFeatures(result.user.features));
    dispatch(profileSlice.actions.setCompany(result.employer));
    return result.user;
};

export const selectLoggedInUserCompany = (state: RootState): CompanyDto => state[profileSlice.name].company;
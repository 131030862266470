import Text from 'components/dist/atoms/Text';
import React from 'react';
import { PageErrorGraph } from 'src/icons/page-error-graph';

import { AppErrorBoundaryStyles } from './app-error-boundary.styles';

export const AppErrorBoundary = () => (<AppErrorBoundaryStyles.Container>
    <AppErrorBoundaryStyles.Message>
        <PageErrorGraph />
        <Text weight="medium" size="xl">Something went wrong</Text>
        <Text variant='secondary'>
            Page unable to load due to a system error. Please refresh page to continue.
        </Text>
        <Text variant='secondary'>If problem persists please  <a
            className='underline'
            href="mailto:support@mysherpas.ai">contact support.</a></Text>
    </AppErrorBoundaryStyles.Message>
</AppErrorBoundaryStyles.Container>)
import { AppUserForgotPasswordRequestDto, LoginWithConfirmCodeDto, SecurityResponseDto, UpdateCookieTimeoutDto } from 'src/backend';

import { baseApi } from './baseApi';

export const authApi = baseApi.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
    endpoints: (build) => ({
        updateCookieTimeout: build.mutation<void, UpdateCookieTimeoutDto>({
            query: (data) => ({
                url: `/v1/auth/updateCookieTimeout`,
                method: 'PUT',
                data,
            })
        }),
        loginWithConfirmCode: build.mutation<SecurityResponseDto, LoginWithConfirmCodeDto>({
            query: (data) => ({
                url: `/v1/noauth/loginWithConfirmCode`,
                method: 'POST',
                data,
            })
        }),
        resendConfirmationCode: build.mutation<SecurityResponseDto, AppUserForgotPasswordRequestDto>({
            query: (data) => ({
                url: `/v1/noauth/resendConfirmationCode`,
                method: 'POST',
                data
            })
        }),
    }),
    overrideExisting: true,
})

export const {
    useResendConfirmationCodeMutation,
    useUpdateCookieTimeoutMutation,
    useLoginWithConfirmCodeMutation,
} = authApi;



